/* eslint-disable no-alert */

export const columnsRolesList = (lang) => [
  {
    label: "Id",
    field: "id",
  },
  {
    label: lang === "pl" ? "Nazwa techniczna" : "Name",
    field: "name",
  },
  {
    label: lang === "pl" ? "Nazwa" : "Label",
    field: "label",
    tdClass: "td-15",
  },
  {
    label: lang === "pl" ? "Aktywny" : "Active",
    field: "isActive",
  },
  {
    label: lang === "pl" ? "Czas" : "Duration",
    field: "duration",
  },
  {
    label: lang === "pl" ? "Utworzono" : "Created",
    field: "createdAt",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "dd.MM.yyyy",
  },
  {
    label: lang === "pl" ? "Zaktualizowano" : "Updated",
    field: "updatedAt",
    type: "date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "dd.MM.yyyy",
  },
  {
    label: lang === "pl" ? "Akcje" : "Action",
    field: "action",
    sortable: false,
    width: "200px",
  },
];
