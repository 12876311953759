<template>
  <div
    style="
      height: 100%;
      width: 100%;
      position: fixed;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.95);
      flex-direction: column;
      z-index: 99;
    "
    v-if="isLoading"
  >
    <b-spinner style="width: 120px; height: 120px" variant="primary" label="Loading..."></b-spinner>
    <h1 class="font-weight-bold mt-3">ŁADOWANIE...</h1>
  </div>
</template>

<script>
export default {
  name: "loadingContent",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
