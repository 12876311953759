<template>
  <div id="roles" class="h-100">
    <b-card header="Filtry" header-bg-variant="transparent" no-body>
      <b-card-body class="d-flex justify-content-around transition-filter">
        <!-- NAME FILTER -->
        <multi-select
          v-reset-input.right
          class="mx-3"
          taggable
          ref="filters_name"
          name="name"
          @tag="(val) => (filters.name = val)"
          :tagPlaceholder="$t('ALERT.PRESS_ENTER_SEARCH')"
          v-model="filters.name"
          noOptions="Brak wyników"
          :placeholder="$t('TABLE.NAME')"
          :options="mapArray(filteredRoles, 'name')"
          :showLabels="false"
        >
          <template slot="noOptions">Brak wyników</template>
        </multi-select>
        <!-- LABEL FILTER -->
        <multi-select
          v-reset-input.right
          selectLabel="Naciśnij ENTER"
          class="mx-3"
          v-model="filters.label"
          ref="filters_label"
          taggable
          name="label"
          @tag="(val) => (filters.label = val)"
          :custom-label="(i) => i.slice(0, 14) + (i.length > 14 && '...')"
          :tagPlaceholder="$t('ALERT.PRESS_ENTER_SEARCH')"
          :placeholder="$t('TABLE.LABEL')"
          :options="mapArray(filteredRoles, 'label')"
          :showLabels="false"
        >
          <template slot="noOptions">Brak wyników</template>
        </multi-select>
        <!-- ACTIVE FILTER -->
        <multi-select
          v-reset-input.right
          class="mx-3"
          v-model="filters.isActive"
          ref="filters_isActive"
          :searchable="false"
          :placeholder="$t('TABLE.IS_ACTIVE')"
          name="isActive"
          :options="['true', 'false']"
          :showLabels="false"
        >
          <template slot="option" slot-scope="props">
            <span>{{ $t(`TABLE.IS_ACTIVE_${props.option.toUpperCase()}`) }}</span>
          </template>
          <template slot="singleLabel" slot-scope="props">
            <span>{{ $t(`TABLE.IS_ACTIVE_${props.option.toUpperCase()}`) }}</span>
          </template>
        </multi-select>
        <!-- DURATION FILTER -->

        <multi-select
          v-reset-input.right
          multiple
          class="mx-3"
          :searchable="false"
          v-model="filters.duration"
          ref="filters_duration"
          name="duration"
          :placeholder="$t('TABLE.DURATION')"
          :options="['infinite', 'instant']"
          :showLabels="false"
          readonly
        >
          <template slot="option" slot-scope="props">
            <span>{{ $t(`TABLE.${props.option.toUpperCase()}_SELECT`) }}</span>
          </template>

          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen"
              >{{ values.length }} wybrano</span
            ></template
          >
        </multi-select>
        <!-- CREATED_AT FILTER -->
        <div class="d-flex mx-3">
          <date-picker
            v-reset-input.right
            range
            ref="filters_createdAt"
            v-model="filters.createdAt"
            valueType="format"
            :clearable="false"
            :placeholder="$t('TABLE.CREATED_AT')"
          />
        </div>

        <!-- updated_AT FILTER -->
        <div class="d-flex mx-3">
          <date-picker
            v-reset-input.right
            range
            v-model="filters.updatedAt"
            ref="filters_updatedAt"
            valueType="format"
            :clearable="false"
            :placeholder="$t('TABLE.UPDATED_AT')"
          />
        </div>
      </b-card-body>
    </b-card>

    <b-card class="mt-4" no-body>
      <b-card-header
        header-bg-variant="transparent"
        class="d-flex align-items-center justify-content-between"
      >
        <span>Role dostepowe</span>
        <div>
          <b-button
            v-if="!isObjectEmpty(filters)"
            class="mx-3"
            variant="light"
            @click="filters = {}"
            >{{ $t("TABLE.CLEAR") }}</b-button
          >
          <b-button
            class="text-white"
            variant="primary"
            @click="$router.push({ name: 'newRole' })"
            >{{ $t("ROLE.ADD") }}</b-button
          >
        </div>
      </b-card-header>
      <b-card-body>
        <!-- ACTUAL FILTER BEGIN -->
        <div class="d-flex my-2 align-items-center" v-if="filters && !isObjectEmpty(filters)">
          <span>Aktualne filtry:</span>
          <div class="d-flex" v-for="(filter, index) in filters" :key="filter + index">
            <div class="div" v-if="filter && index !== 'duration'">
              <b-badge
                variant="info"
                class="mx-2 d-flex align-items-center cursor-pointer p-2 font-weight-normal"
                v-b-hover="(isHovered) => hoverFilter(isHovered, index)"
                @click="deleteActiveFilter(index)"
              >
                {{
                  "true false".includes(filter)
                    ? $t(`TABLE.${filter}`)
                    : typeof filter === "object"
                    ? filter.join(" do ")
                    : filter
                }}
                <v-icon class="ml-2 text-white" small>fas fa-times</v-icon>
              </b-badge>
            </div>
            <div v-else v-for="item in filter" :key="item">
              <b-badge
                :key="item"
                variant="info"
                class="mx-2 d-flex align-items-center cursor-pointer p-2 font-weight-normal"
                v-b-hover="(isHovered) => hoverFilter(isHovered, index)"
                @click="deleteActiveFilter(index, item)"
              >
                {{ $t(`TABLE.${item.toUpperCase()}`) }}
                <v-icon class="ml-2 text-white" small>fas fa-times</v-icon>
              </b-badge>
            </div>
          </div>
        </div>

        <!-- ACTUAL FILTER END-->
        <vue-good-table
          :columns="columns"
          :rows="filteredRoles"
          :isLoading="isLoading"
          :pagination-options="{
            enabled: true,
            perPage: currentPerPage,
          }"
        >
          <template slot="table-row" slot-scope="{ column, row }">
            <div class="d-flex" v-if="column.field === 'action'">
              <b-button
                size="sm"
                class="mx-1 text-white py-0 px-2 m-0"
                variant="primary"
                @click="$router.push({ name: 'RoleID', params: { id: row.id, role: row } })"
                >{{ $t("TABLE.SHOW") }}</b-button
              >
              <b-button
                size="sm"
                class="mx-1 text-white py-0 px-2 m-0"
                @click="$router.push({ name: 'editRole', params: { id: row.id, role: row } })"
              >
                {{ $t("TABLE.EDIT") }}</b-button
              >
              <b-button
                size="sm"
                class="mx-1 text-white py-0 px-2 m-0"
                variant="danger"
                @click="deleteUser(row.id)"
                >{{ $t("TABLE.DELETE") }}</b-button
              >
            </div>
            <div class="d-flex justify-content-center" v-else-if="column.field === 'isActive'">
              <b-badge class="text-white" :variant="row.isActive ? 'success' : 'danger'">
                {{ $t(`TABLE.IS_ACTIVE_${row.isActive.toString().toLocaleUpperCase()}`) }}
              </b-badge>
            </div>
            <div v-else-if="column.field === 'duration'">
              {{
                $t(`TABLE.${row.duration.type.toLocaleUpperCase()}`, {
                  expires_on: row.duration.expires_on && row.duration.expires_on.split(" ")[0],
                  begins_on: row.duration.begins_on && row.duration.begins_on.split(" ")[0],
                  expires_after: row.duration.expires_after,
                })
              }}
            </div>
          </template>
          <template slot="loadingContent">
            <LoadingContent is-loading />
          </template>

          <!--  Pagination emptystate -->
          <template slot="emptystate">
            <h2 class="text-black-50 text-center font-weight-bold">{{ $t("NO_RESULT") }}</h2>
          </template>

          <!--  Pagination dataTable -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex mt-4 justify-content-between" style="height: 38px">
              <multi-select
                style="width: 120px"
                :showLabels="false"
                :options="paginationOptions"
                placeholder="Wybierz"
                v-model="currentPerPage"
                @input="(val) => props.perPageChanged({ currentPerPage: val })"
              />
              <b-pagination
                @change="(currentPage) => props.pageChanged({ currentPage: currentPage })"
                :total-rows="props.total"
                :per-page="currentPerPage"
                :value="1"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DELETE_ROLE, GET_ROLES } from "@/core/store/Roles/roles.module";
import { columnsRolesList } from "@/views/Pages/Roles/RolesList.filter";
import LoadingContent from "@/components/LoadingContent/LoadingContent.vue";

export default {
  name: "RolesList",
  components: { LoadingContent },
  data() {
    return {
      isLoading: true,
      filters: { name: null, label: null, isActive: null },
      currentPerPage: 10,
      paginationOptions: [10, 15, 20, 40],
      classHoverFilter: "shadow",
      columns: columnsRolesList(this.$i18n.locale),
    };
  },
  methods: {
    hoverFilter(isHovered, nameFilter, deleteFilter = false) {
      const refInputFilter = this.$refs[`filters_${nameFilter}`];
      if (refInputFilter) refInputFilter.$el.classList.toggle(this.classHoverFilter);
      if (refInputFilter && deleteFilter) {
        refInputFilter.$el.classList.remove(this.classHoverFilter);
      }
    },
    deleteActiveFilter(name, elArray) {
      const filter = this.filters[name];
      const indexEl = elArray && this.filters[name].findIndex((i) => i === elArray);

      if (elArray && filter.length !== 1) filter.splice(indexEl, 1);
      else this.$set(this.filters, name, null);

      this.hoverFilter(null, name, true);
    },
    deleteUser(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("SURE"), {
          okTitle: "Tak",
          footerClass: "text-white",
          cancelTitle: "Anuluj",
        })
        .then((value) => {
          if (value) {
            this.$store
              .dispatch(DELETE_ROLE, id)
              .then(() => {
                this.rolesDispatch();
                this.toastSuccess(this.$t("SUCCESS"), this.$t("SUCCESS"));
              })
              .catch(() => {
                this.toastError("Warning", "You can't delete this role ");
              });
          }
        });
    },
    rolesDispatch() {
      this.isLoading = true;
      this.$store.dispatch(GET_ROLES).then(() => {
        this.isLoading = false;
      });
    },
  },
  computed: {
    filteredRoles() {
      // prettier-ignore
      // eslint-disable-next-line max-len
      return this.getRoles && this.getRoles.list.filter((i) => Object.keys(this.filters)
        .every((filter) => {
          if (filter === "duration" && this.filters[filter]) {
            const filterArrayString = this.filters[filter].join(" ");
            return filterArrayString.includes(i[filter].type);
          }
          if (filter === "updatedAt" || filter === "createdAt") {
            if (this.filters[filter]) {
              const start = new Date(this.filters[filter][0]).getTime();
              const end = new Date(this.filters[filter][1]).getTime();
              const checkDate = new Date(i[filter]).getTime();
              return (checkDate >= start && checkDate <= end);
            }
            return true;
          }
          return this.filters[filter] ? i[filter].toString().includes(this.filters[filter]) : true;
        }));
    },
    ...mapGetters(["getRoles"]),
  },
  mounted() {
    this.rolesDispatch();
  },
};
</script>

<style lang="scss">
.transition-filter {
  .multiselect,
  .mx-datepicker {
    transition: 0.4s ease-in-out;
  }
}

.reset-input {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  padding: 4px;
  background: white;
  z-index: 99999;
  cursor: pointer;
}
</style>
